import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableColumn } from '@nstep-common/core';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { createProxy } from '@nstep-common/utils';
import {
	BalanceOverviewModel,
	BalanceExpirationDateModel,
	AddBalanceConfirmationModel,
	AddBalanceConfirmationModelValidator,
	AddBalanceReasonDto,
	AllowedDto,
	AddBalanceActionModel,
	OperatorBulkTransactionModel,
	DateSelectionInputType
} from '@nstep-public/pages';
import { ValidationErrors } from 'fluentvalidation-ts';
import { chain } from 'lodash';

@Component({
	selector: 'app-confirmation',
	templateUrl: './confirmation.component.html',
})
export class ConfirmationComponent implements OnInit {

	@Input() expirationPeriods: BalanceExpirationDateModel[] = [];
	@Input() reasons: AddBalanceReasonDto[] = [];
	@Input() balanceConfirmationItem!: BalanceOverviewModel;
	@Input() selectedRationedItem!: AllowedDto;

	@Output() action = new EventEmitter<AddBalanceActionModel>();

	selectedExpirationDate: string | null = null;

	expirationPeriodsDropdownValues: DropdownOption[] = [];
	reasonsDropdownValues: DropdownOption[] = [];

	tableData: any[] = [];
	tableDataReady = true;

	tableColumns: TableColumn[] = [
		{ name: 'Entitlement Type', key: 'entitlementType', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Amount', key: 'unitCount', isCellCentered: true, isHeaderCentered: true },
		{ name: 'New Balance', key: 'newBalance', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Unit', key: 'measurementUnit', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Unit Description', key: 'measurementUnitDescription', isCellCentered: true, isHeaderCentered: true }
	];

	today = new Date(new Date().toDateString());

	calendarSettings = {
		type: 'date',
		minDate: new Date(this.today.setDate(this.today.getDate() + 1))
	}

	isValid = false;

	confirmationModel: AddBalanceConfirmationModel = createProxy(new AddBalanceConfirmationModel(), {
		set: () => this.validate(this.confirmationModel)
	});

	validation: ValidationErrors<AddBalanceConfirmationModel> = {};

	validate(value: AddBalanceConfirmationModel): void {
		const validator = new AddBalanceConfirmationModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	ngOnInit(): void {

		if (this.selectedRationedItem.issuance.periodCycle != 0 && this.selectedRationedItem.issuance.periodCycle != -1) {
			this.confirmationModel.dateSelectionType = DateSelectionInputType.Dropdown;
			this.confirmationModel.expirationPeriodId = null;
		}

		if (this.selectedRationedItem.issuance.periodCycle == 0 && this.selectedRationedItem.issuance.periodCycle == -1) {
			this.confirmationModel.dateSelectionType = DateSelectionInputType.Calendar;
			this.confirmationModel.expirationDate = null;
		}

		this.confirmationModel.reasonId = null;
		this.tableData = [{ ...this.balanceConfirmationItem }];

		this.expirationPeriodsDropdownValues = chain(this.expirationPeriods)
			.map(e => new DropdownOption({
				value: e.id,
				name: e.name
			}))
			.value();

		this.reasonsDropdownValues = chain(this.reasons)
			.map(e => new DropdownOption({
				value: e.id,
				name: e.name
			}))
			.value();
	}

	onExpirationPeriodsValueChange(): void {
		this.selectedExpirationDate = this.expirationPeriods.find(e => e.id === this.confirmationModel.expirationPeriodId)!.expirationDate;
	}

	cancel(): void {
		this.action.emit({ action: 'cancel', currentTabIndex: 2 });
	}

	next(): void {
		this.validate(this.confirmationModel);

		if (Object.keys(this.validation).length != 0) {
			return;
		}

		const model = new OperatorBulkTransactionModel({
			amisCardId: this.selectedRationedItem.amisCardId,

			headquarterId: this.selectedRationedItem.headquarterId,
			entitlementCodeId: this.selectedRationedItem.entitlementCodeId,
			entitlementTypeId: this.selectedRationedItem.entitlementTypeId,
			transactionAmount: this.balanceConfirmationItem.unitCount,
			measurementUnitId: this.selectedRationedItem.measurementUnitId,
			notes: `Reason: ${this.reasonsDropdownValues.find(r => r.value === this.confirmationModel.reasonId)!.name}${this.confirmationModel.notes ? `; Notes: ${this.confirmationModel.notes}` : ''}`,
			endDate: this.confirmationModel.dateSelectionType === DateSelectionInputType.Dropdown ? this.selectedExpirationDate : this.confirmationModel.expirationDate?.toDateString(),
		});

		this.action.emit({ action: 'next', currentTabIndex: 2, saveItem: model });
	}
}
