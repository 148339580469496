<app-authentication [userNamePlaceHolder]="userNamePlaceholder"
	[passwordPlaceHolder]="passwordPlaceholder"
	[userNameMaxLength]="userNameMaxLength"
	[passwordMaxLength]="passwordMaxLength"
	[requires2F]="requires2F"
	[validation]="validation"
	[errors]="errors"
	[loading]="loading"
	(validateEvent)="validate($event)"
	(submitEvent)="onSubmit($event)">
</app-authentication>