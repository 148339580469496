import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ValidationErrors } from 'fluentvalidation-ts';
import { flatten } from 'lodash';
import { EMPTY, catchError, finalize, tap } from 'rxjs';

import { AuthService, AuthenticationModel, BaseComponent } from '@nstep-common/core';
import { AppSource } from '@nstep-common/utils';
import { LoginModelValidator, LoginOperatorModelValidator } from '@nstep-public/pages';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html'
})
export class LoginComponent extends BaseComponent implements OnInit {
	validation: ValidationErrors<AuthenticationModel> = {};
	errors: string[] = [];
	loading: boolean = false;

	isOperatorRoute = false;

	userNamePlaceholder = '';
	userNameMaxLength: number | null = null;

	passwordPlaceholder = '';
	passwordMaxLength: number | null = null;

	requires2F: boolean = false;

	constructor(private authService: AuthService,
		private router: Router) {
		super();
	}

	ngOnInit(): void {
		if (this.authService.JWT) {
			this.router.navigate(['']);
		}

		if (this.router.url.endsWith('operator')) {
			this.isOperatorRoute = true;
			this.userNamePlaceholder = 'User Name';
			this.passwordPlaceholder = 'Password';
			this.userNameMaxLength = null;
			this.passwordMaxLength = null;
		}
		else {
			this.isOperatorRoute = false;
			this.userNamePlaceholder = 'AMIS CARD NUMBER';
			this.passwordPlaceholder = 'PIN';
			this.userNameMaxLength = 14;
			this.passwordMaxLength = 4;
		}
	}

	validate(value: AuthenticationModel): void {
		const validator = this.isOperatorRoute ? new LoginOperatorModelValidator() : new LoginModelValidator();
		this.validation = validator.validate(value)
	}

	onSubmit(value: AuthenticationModel): void {
		this.validate(value);

		if (Object.keys(this.validation).length != 0) {
			return;
		}

		this.loading = true;

		const logIn = this.authService
			.logIn(value)
			.pipe(
				tap(jwt => {
					const redirectUrl = this.isOperatorRoute && jwt.role == 'Operator'
						? 'select-location'
						: '';

					this.router.navigate([redirectUrl]);
				}),
				catchError((resp: any) => {
					const errors = flatten(Object.values(resp)) as string[];

					if (errors.includes('Requires 2F.')) {
						this.requires2F = true;
						return EMPTY;
					}

					if (errors.includes('Password requires reset.')) {
						this.router.navigate(['change-password'], {
							state: {
								userName: value.userName,
								appSource: AppSource.Internal
							}
						});
						
						return EMPTY;
					}

					this.errors = errors;

					return EMPTY;
				}),
				finalize(() => this.loading = false)
			)
			.subscribe();

		this.subscriptions.push(logIn);
	}
}
