import { ExtraValidator } from "@nstep-common/utils";
import { PinConfirmationModel } from "@nstep-public/pages";

export class PinConfirmationModelValidator extends ExtraValidator<PinConfirmationModel> {
	constructor() {
		super();

		this.ruleFor('pin')
			.matches(/^[0-9]{4}$/)
				.withMessage('The PIN number is not valid!');
	}
}