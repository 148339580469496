<h2 class="ui header">Transaction History</h2>

<div class="ui form">
	<div class="three fields">
		<div class="field">
			<label>Card Number:</label>
			<sm-dropdown class="ui fluid selection dropdown label"
				[class.loading]="!cardsDropdownValues.length"
				[(value)]="selectedCardNumber"
				[options]="cardsDropdownValues"
				(valueChange)="onCardValueChange()">
				<div class="text"></div>
				<i class="dropdown icon"></i>
			</sm-dropdown>
		</div>

		<div class="field">
			<label>Entitlement Type:</label>
			<sm-dropdown class="ui fluid selection dropdown label"
				[class.loading]="!cardEntitlementTypesDropdownValues.length"
				[(value)]="selectedEntitlementType"
				[options]="cardEntitlementTypesDropdownValues"
				(valueChange)="onEntitlementTypeValueChange()">
				<div class="text"></div>
				<i class="dropdown icon"></i>
			</sm-dropdown>
		</div>
	</div>

	<div class="field">
		<app-table-view
			[data]="tableData"
			[noSearch]="true"
			[dataReady]="tableDataReady"
			[columns]="tableColumns">
		</app-table-view>
	</div>
</div>

<ng-template #transactionHistoryActionCell let-item="item">
	<td style="text-align: center;">
		{{ item.displayNotes }}
	</td>

	<td style="text-align: center;">
		<a *ngIf="item.notes && item.notes.length > notesMaximumDisplayLength" href="javascript:void(0);" (click)="toggleNotes(item)">{{ item.action }}</a>
	</td>
</ng-template>