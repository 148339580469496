import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { AggregateTransactions, ExpirationPeriodsQueryModel, OperatorBulkTransactionModel, PurchaseBalanceDto } from '@nstep-public/pages';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class OnlineTransactionService {

	private pin = new BehaviorSubject<string>('');
	currentPinValue = this.pin.asObservable();

	constructor(private apiService: ApiService) { }

	getPurchaseBalance(cardNumber: string, locationId: number): Observable<PurchaseBalanceDto> {
		return this.apiService.get(PurchaseBalanceDto, 'transactions/manage/purchase', { params: { card: cardNumber, location: locationId } });
	}

	createPurchaseTransactions(locationId: number, cardNumber: string, hash: string, purchase: AggregateTransactions): Observable<PurchaseBalanceDto> {
		return this.apiService.postNoContent('transactions/manage/purchase', purchase, { params: { location: locationId, cardno: cardNumber, hash: hash } });
	}

	getAddBalanceReasons(): Observable<any> {
		return this.apiService.get(Any, 'transactions/manage/add-balance-reasons');
	}

	getBulkTransactions(model: OperatorBulkTransactionModel): Observable<any> {
		return this.apiService.get(Any, 'transactions/manage/history', { params: model });
	}

	getExpirationPeriods(model: ExpirationPeriodsQueryModel): Observable<any> {
		return this.apiService.get(Any, 'transactions/manage/expiration-periods', { params: model });
	}

	saveEntitlementBulkTransaction(model: OperatorBulkTransactionModel): Observable<any> {
		return this.apiService.postNoContent('transactions/manage/add', model);
	}

	changePin(pin: string): void {
		this.pin.next(pin);
	}

	clearOnlineTransactionsCache(): void {
		this.apiService.invalidateCache('transactions/manage/purchase');
	}

	clearAddBalanceHistoryCache(): void {
		this.apiService.invalidateCache('transactions/manage/history');
	}
}
