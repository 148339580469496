import { formatDate } from '@angular/common';
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent, TableColumn } from "@nstep-common/core";
import { DropdownOption } from '@nstep-common/semantic-ui';
import { CardNumberEntitlementTypes, GroupedTransactionsOnline } from '@nstep-public/pages';
import { TransactionHistoryService } from '@nstep-public/shared';
import { chain } from 'lodash';

@Component({
	selector: 'app-transaction-history',
	templateUrl: './transaction-history.component.html'
})
export class TransactionHistoryComponent extends BaseComponent implements OnInit, AfterViewInit {
	@ViewChild('transactionHistoryActionCell') transactionHistoryActionCell?: TemplateRef<any>;

	tableData: any[] = [];
	tableDataReady = true;

	tableColumns: TableColumn[] = [
		{ name: 'Headquarter', key: 'headquarter', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Entitlement Code', key: 'entitlementCode', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Measurement Unit', key: 'measurementUnit', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Amount', key: 'transactionAmount', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Time Stamp', key: 'timeStamp', isCellCentered: true, isHeaderCentered: true },
		{ name: 'POS Location', key: 'posLocation', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Status', key: 'transactionStatus', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Transaction Type', key: 'transactionType', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Start Date', key: 'startDate', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Expiration Date', key: 'expirationDate', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Notes', isHeaderCentered: true },
		{ name: 'Action', isHeaderCentered: true },
	];

	cardsDropdownValues: DropdownOption[] = [];
	selectedCardNumber: string | null = null;
	
	cardEntitlementTypesDropdownValues: DropdownOption[] = [];
	selectedEntitlementType: string | null = null;

	cardNumberTypes: CardNumberEntitlementTypes[] = [];

	groupedTransactions: GroupedTransactionsOnline[] = [];

	notesMaximumDisplayLength = 25;

	constructor(private transactionHistoryService: TransactionHistoryService) {
		super();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.tableColumns[11].template = this.transactionHistoryActionCell;
		});
	}

	ngOnInit(): void {
		this.initializeTable();
	}

	initializeTable(): void {
		this.tableData = [];
		this.tableDataReady = false;

		this.cardNumberTypes = [];

		this.subscriptions.push(
			this.transactionHistoryService.getUserTransactions().subscribe({
				next: (response: GroupedTransactionsOnline[]) => {

					this.groupedTransactions = response;

					this.groupedTransactions.forEach(group => {
						const currentCardNumberTypes: CardNumberEntitlementTypes = {
							cardNumber: group.cardNumber,
							entitlementTypes: group.types.flatMap(type => type.entitlementType)
						};

						this.cardNumberTypes.push(currentCardNumberTypes);
					});

					const cardsDropdownItems: DropdownOption[] = [];

					this.cardNumberTypes.forEach((item, cardIndex) => {

						const cardDropdownItem = new DropdownOption({
							name: item.cardNumber,
							value: item.cardNumber
						});

						cardsDropdownItems.push(cardDropdownItem);

						this.cardsDropdownValues = cardsDropdownItems;

						if (cardIndex === 0) {

							const cardEntitlementTypes = item.entitlementTypes.map(type => {

								return new DropdownOption({
									name: type,
									value: type
								});
							});

							this.cardEntitlementTypesDropdownValues = cardEntitlementTypes;
						}
					});

					this.repopulateTable(this.cardsDropdownValues[0].value, this.cardEntitlementTypesDropdownValues[0].value);
				},
				error: () => {
					this.tableDataReady = true;
				}
			})
		);
	}

	onCardValueChange(): void {
		const currentCardEntitlementTypes = this.cardNumberTypes
			.find(group => group.cardNumber === this.selectedCardNumber)!.entitlementTypes
			.map(type => {
				return new DropdownOption({
					name: type,
					value: type
				});
			});

		this.cardEntitlementTypesDropdownValues = currentCardEntitlementTypes;

		this.tableData = [];
		this.tableDataReady = false;

		this.repopulateTable(this.selectedCardNumber!, this.cardEntitlementTypesDropdownValues[0].value);
	}

	onEntitlementTypeValueChange(): void {
		this.tableData = [];
		this.tableDataReady = false;

		this.repopulateTable(this.selectedCardNumber!, this.selectedEntitlementType!);
	}

	toggleNotes(item: any) {
		item.displayNotes = item.action === 'Show Notes' ? item.notes : item.notes.substring(0, this.notesMaximumDisplayLength);
		item.action = item.action === 'Show Notes' ? 'Hide Notes' : 'Show Notes';
	}

	private repopulateTable(cardNumber: string, entitlementType: string): void {

		const transactions = this.groupedTransactions
			.find(group => group.cardNumber === cardNumber)!.types
			.flatMap(type => type.transactions)
			.filter(trsansaction => trsansaction.entitlementType === entitlementType);

		this.tableData = chain(transactions)
			.map(e => {

				const largerThan25Characters = e.notes?.length > this.notesMaximumDisplayLength;

				return {
					headquarter: e.headquarter,
					entitlementCode: e.entitlementCode,
					entitlementType: e.entitlementType,
					measurementUnit: e.measurementUnit,
					transactionAmount: e.transactionAmount,
					timeStamp: formatDate(new Date(e.timeStamp), 'dd/MM/yyyy - h:mm a', 'en'),
					posLocation: e.posLocation,
					transactionStatus: e.transactionStatus,
					transactionType: e.transactionType,
					startDate: e.startDate ? formatDate(new Date(e.startDate), 'dd/MM/yyyy - h:mm a', 'en') : '-',
					expirationDate: e.expirationDate ? formatDate(new Date(e.expirationDate), 'dd/MM/yyyy - h:mm a', 'en') : '-',
					notes: e.notes,
					displayNotes: largerThan25Characters ? e.notes.substring(0, this.notesMaximumDisplayLength) : e.notes,
					action: largerThan25Characters ? 'Show Notes' : 'Hide Notes'
				}
			})
			.value();

		this.tableDataReady = true;
	}
}
