import { Injectable } from "@angular/core";
import { ApiService, ExportDataRequest, ReportPagedQueryParameter } from "@nstep-common/core";
import { Any, PagedQueryParameter } from "@nstep-common/utils";
import { Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class ReportingService {

	constructor(private apiService: ApiService) {
	}

	getDefaultData(): Observable<any> {
		return this.apiService.get(Any, 'reporting/default-data');
	}

	getReports(model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, 'reporting', { observe: 'response', params: model });
	}

	getReportSettings(reportId: number): Observable<any> {
		return this.apiService.get(Any, `reporting/${reportId}`);
	}

	getReportTemplate(templateId: number, model: ReportPagedQueryParameter): Observable<any> {
		return this.apiService.post(Any, `reporting/${templateId}`, model, { observe: 'response' });
	}

	exportReportTemplate(reportId: number, model: ExportDataRequest): Observable<any> {
		return this.apiService.post(Any, `reporting/${reportId}/export`, model, { observe: 'response', responseType: 'blob' });
	}
}