<div class="ui divider"></div>

<div class="ui form">
	<h3 class="ui header">2. Overview</h3>
	<div class="field">
		<app-table-view
			[itemTemplate]="confirmationTemplate"
			[data]="tableData"
			[noSearch]="true"
			[dataReady]="tableDataReady"
			[columns]="tableColumns">
		</app-table-view>
	</div>

	<div class="four fields">
		<ng-container *ngIf="expirationPeriods.length">
			<div class="field" app-field *ngIf="confirmationModel.dateSelectionType === 'Dropdown'" [error]="validation | path: 'expirationPeriodId'">
				<label>Expiration Date</label>
				<sm-dropdown class="ui selection dropdown"
					[class.loading]="!expirationPeriodsDropdownValues.length"
					[(value)]="confirmationModel.expirationPeriodId"
					[options]="expirationPeriodsDropdownValues"
					(valueChange)="onExpirationPeriodsValueChange()">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>

			<div class="field" app-field *ngIf="confirmationModel.dateSelectionType === 'Calendar'" [error]="validation | path: 'expirationDate'">
				<label>Expiration Date</label>
				<sm-calendar class="ui calendar"
					[settings]="calendarSettings"
					[(value)]="confirmationModel.expirationDate">
					<div class="ui fluid input left icon">
						<i class="calendar icon"></i>
						<input type="text" placeholder="Date" />
					</div>
				</sm-calendar>
			</div>
			<i *ngIf="selectedExpirationDate">The ration will expire on: {{selectedExpirationDate | date:'dd/MM/yyyy'}}</i>
		</ng-container>

		<div class="field" app-field [error]="validation | path: 'reasonId'">
			<label>Reason</label>
			<sm-dropdown class="ui selection dropdown"
				[class.loading]="!reasonsDropdownValues.length"
				[(value)]="confirmationModel.reasonId"
				[options]="reasonsDropdownValues">
				<div class="text"></div>
				<i class="dropdown icon"></i>
			</sm-dropdown>
		</div>
	</div>

	<div class="four fields">
		<div class="field">
			<label>Notes</label>
			<textarea style="height: 135px; resize: none;" [(ngModel)]="confirmationModel.notes" app-trim></textarea>
		</div>
	</div>

	<div class="fields">
		<div class="field">
			<button class="ui negative basic button" (click)="cancel()">Return</button>
		</div>
		<div class="field">
			<button class="ui positive basic button" [disabled]="!isValid" (click)="next()">Confirm</button>
		</div>
	</div>
</div>

<ng-template #confirmationTemplate let-item="item">
	<tr style="text-align: center;">
		<td>{{item.entitlementType}}</td>
		<td>+{{item.unitCount}}</td>
		<td>{{item.newBalance}}</td>
		<td>{{item.measurementUnit}}</td>
		<td>{{item.measurementUnitDescription}}</td>
	</tr>
</ng-template>