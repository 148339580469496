import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@nstep-common/core';
import { toast } from '@nstep-common/utils';
import {
	AddBalanceReasonDto,
	AllowedDto,
	BalanceExpirationDateModel,
	ExpirationPeriodsQueryModel,
	ExtendedPOSLocationDto,
	PurchaseBalanceDto,
	BalanceOverviewModel,
	AddBalanceActionModel,
	OperatorBulkTransactionModel
} from '@nstep-public/pages';
import { AmisCardService, CancelTransactionComponent, OnlineTransactionService, OperatorService } from '@nstep-public/shared';
import { chain, flatten } from 'lodash';

@Component({
	selector: 'app-add-balance',
	templateUrl: './add-balance.component.html',
})
export class AddBalanceComponent extends BaseComponent implements OnInit {

	@ViewChild(CancelTransactionComponent) cancelTransactionModal!: CancelTransactionComponent;

	currentDate = new Date();
	currentPosLocationInfo!: ExtendedPOSLocationDto;
	purchaseBalanceData!: PurchaseBalanceDto;

	loading = false;
	attempts = 0;

	tabs = ['searchCard', 'rationedTypesPick', 'confirmTransaction'];
	currentTab = this.tabs[0];

	addBalanceReasons!: AddBalanceReasonDto[];
	rationedItems: AllowedDto[] = [];

	selectedRationedItem: AllowedDto = {} as AllowedDto;
	expirationPeriods: BalanceExpirationDateModel[] = [];

	balanceConfirmationItem: BalanceOverviewModel | null = null;

	sentTransaction = false;
	transactionDate = '';

	constructor(private router: Router,
		private onlineTransactionService: OnlineTransactionService,
		private amisCardService: AmisCardService,
		private operatorService: OperatorService) {
		super();
	}

	ngOnInit(): void {
		if (!this.operatorService.getLocationInfo()) {
			this.router.navigate(['select-location']);
		} else {
			this.currentPosLocationInfo = JSON.parse(this.operatorService.getLocationInfo());
		}

		setInterval(() => {
			this.currentDate = new Date();
		}, 1000);
	}

	search(amisCardNumber: string): void {
		this.loading = true;
		this.addBalanceReasons = [];

		this.subscriptions.push(
			this.onlineTransactionService.getAddBalanceReasons().subscribe({
				next: (response: AddBalanceReasonDto[]) => {
					this.addBalanceReasons = response;
				},
				error: () => {
					this.loading = false;
				}
			})
		);

		this.subscriptions.push(
			this.onlineTransactionService.getPurchaseBalance(amisCardNumber, this.currentPosLocationInfo.location.id).subscribe({
				next: (response: PurchaseBalanceDto) => {
					this.purchaseBalanceData = response;

					this.sentTransaction = false;

					const today = new Date(new Date().toDateString());

					this.purchaseBalanceData.allowed.forEach(item => {

						const isValid = item.issuance && item.issuance.isBuyable ?
							item.issuance.startDate ? new Date(item.issuance.startDate.toDateString()) <= today : true &&
								item.issuance.EndDate ? new Date(item.issuance.EndDate.toDateString()) >= today : true
							: false;

						if (item.rationed && isValid) {
							item.parsedBalance = parseInt(item.balance);
							this.rationedItems.push(item);
						}
					});

					this.rationedItems = chain(this.rationedItems)
						.orderBy(d => d.entitlementType)
						.value();

					if (!this.rationedItems.length) {
						this.cancelTransaction();
						this.loading = false;
						toast('', 'You cannot add balance to any entitlement!', 'red');
					} else {
						this.loading = false;
						this.currentTab = this.tabs[1];
						this.attempts = 1;

						this.selectedRationedItem = this.rationedItems[0];
					}
				},
				error: (response) => {

					this.loading = false;

					const errors: string[] = flatten(Object.values(response));

					errors.forEach(error => {
						toast('', error, 'red');
					});


				}
			})
		);

	}

	onAction(model: AddBalanceActionModel): void {

		if (model.action === 'cancel') {
			this.currentTab = this.tabs[model.currentTabIndex - 1];
		} else {
			if (model.currentTabIndex === 1) {
				this.balanceConfirmationItem = model.overviewItem!;
				this.getExpirationPeriods();
			} else {
				this.saveEntitlementBulkTransaction(model.saveItem!);
			}
		}
	}

	getExpirationPeriods(): void {
		this.loading = true;
		const model: ExpirationPeriodsQueryModel = {
			cardNumber: this.purchaseBalanceData.card.cardNumber,
			entitlementTypeId: this.selectedRationedItem.entitlementTypeId,
			entitlementCodeId: this.selectedRationedItem.entitlementCodeId,
			headquarterId: this.selectedRationedItem.headquarterId
		};

		this.subscriptions.push(
			this.onlineTransactionService.getExpirationPeriods(model).subscribe({
				next: (response: BalanceExpirationDateModel[]) => {
					this.expirationPeriods = response;
					this.loading = false;
					this.currentTab = this.tabs[2]
				},
				error: (response: any) => {

					const errors: string[] = flatten(Object.values(response.error.errors));

					errors.forEach(error => {
						toast('', error, 'red');
					});

					this.loading = false;
				}
			})
		);
	}

	saveEntitlementBulkTransaction(model: OperatorBulkTransactionModel): void {
		this.loading = true;

		model.locationId = this.currentPosLocationInfo.location.id;

		this.subscriptions.push(
			this.onlineTransactionService.saveEntitlementBulkTransaction(model).subscribe({
				next: () => {
					this.onlineTransactionService.clearAddBalanceHistoryCache();
					this.cancelTransaction();
					toast('', 'Transaction was completed successfuly!', 'green');
					this.loading = false;
				},
				error: (response: any) => {
					toast('', response?.error?.detail, 'red');
					this.loading = false;
				}
			})
		);
	}

	cancelTransaction(): void {
		this.rationedItems = [];
		this.balanceConfirmationItem = null;
		this.amisCardService.changeAmisCardNumber('');
		this.currentTab = this.tabs[0];
		this.sentTransaction = false;
	}
}
