import { ExtraValidator } from "@nstep-common/utils";
import { AmisCardModel } from "@nstep-public/shared";

export class OnlineTransactionModelValidator extends ExtraValidator<AmisCardModel> {
	constructor() {
		super();

		this.ruleFor('amisCardNumber')
			.matches(/^([A-Za-z]{3})(-[0-9]{7})(-[0-9]{2})$/)
				.withMessage('The AMIS Card Number is not valid!');
	}
}