import { JsonProperty } from "@nstep-common/utils";

export class CardTransactionOnline {
	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: String })
	headquarter!: string;

	@JsonProperty({ type: String })
	entitlementCode!: string;

	@JsonProperty({ type: String })
	entitlementType!: string;

	@JsonProperty({ type: String })
	measurementUnit!: string;

	@JsonProperty({ type: Number })
	transactionAmount!: number;

	@JsonProperty({ type: Date })
	timeStamp!: Date;

	@JsonProperty({ type: String })
	posLocation!: string;

	@JsonProperty({ type: String })
	transactionStatus!: string;

	@JsonProperty({ type: String })
	transactionType!: string;

	@JsonProperty({ type: Date, nullable: true })
	startDate!: Date | null;

	@JsonProperty({ type: Date, nullable: true })
	expirationDate!: string | null;

	@JsonProperty({ type: String })
	notes!: string;
}

export class GroupedTransactionsByType {
	@JsonProperty({ type: String })
	entitlementType!: string;

	@JsonProperty({ type: CardTransactionOnline, array: true })
	transactions!: CardTransactionOnline[];
}

export class GroupedTransactionsOnline {
	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: GroupedTransactionsByType, array: true })
	types!: GroupedTransactionsByType[];
}

export class CardNumberEntitlementTypes {
	cardNumber!: string;
	entitlementTypes!: string[]
}


