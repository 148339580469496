<app-base-layout>
	<ng-container title>
		<div class="ui green label">Public</div>
	</ng-container>

	<ng-container menu>
		<br/>
		<div id="side-menu"
			class="ui vertical fluid large text menu">
			<a class="blue item"
				[routerLink]="['balance']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.balance]">
				<i class="balance scale left icon"></i>&nbsp;&nbsp;Balance
			</a>

			<a class="blue item"
				[routerLink]="['transaction-history']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.transactionHistory]">
				<i class="exchange alternate icon"></i>&nbsp;&nbsp;Transaction History
			</a>

			<a class="item"
				[routerLink]="['entitlement-check']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.entitlementCheck]">
				<i class="cubes icon"></i>&nbsp;&nbsp;Entitlement Check
			</a>

			<a class="item"
				[routerLink]="['online-transactions']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.onlineTransactions]">
				<i class="exchange alternate icon"></i>&nbsp;&nbsp;Online Transactions
			</a>

			<a class="item"
				[routerLink]="['add-balance']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.addBalance]">
				<i class="balance scale left icon"></i>&nbsp;&nbsp;Add Balance
			</a>

			<a class="item"
				[routerLink]="['select-location']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.changeLocation]">
				<i class="map marker alternate icon"></i>&nbsp;&nbsp;Change Location
			</a>

			<a class="item"
				[routerLink]="['reports']"
				[routerLinkActive]="['active']"
				[appAccessHider]="[permissions.reports]">
				<i class="chart pie icon"></i>&nbsp;&nbsp;Reports
			</a>
		</div>
	</ng-container>

	<ng-container content>
		<router-outlet></router-outlet>
	</ng-container>
</app-base-layout>