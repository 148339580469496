import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { UnrationedBalanceModel } from '@nstep-public/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PosOperatorService {

	constructor(private apiService: ApiService) {
	}

	getAmisCardEntitlements(amisCardNumber: string): Observable<UnrationedBalanceModel> {
		return this.apiService.get(UnrationedBalanceModel, `pos-operator/${amisCardNumber}`);
	}

	getTypes(ids: number[]): Observable<any> {
		return this.apiService.post(Any, 'pos-operator/entitlement-types', ids);
	}
}
