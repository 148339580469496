<div class="ui stackable grid">
	<ng-container *ngIf="currentPosLocationInfo">
		<div class="row">
			<div class="ten wide column">
				<h4>POS Location: <span class="ui green text">{{currentPosLocationInfo.location.name}}</span> &nbsp;&nbsp;AMIS Card: <span class="ui purple text">{{currentPosLocationInfo.card.cardNumber}}</span> </h4>
			</div>
			<div class="right aligned six wide column">
				<p>{{currentDate | date : "dd/MM/yyyy - h:mm:ss"}}</p>
			</div>
		</div>
		<div class="ui divider" style="margin-top: 0; margin-bottom: 0;"></div>
	</ng-container>

	<div class="row">
		<div class="ten wide column">
			<h2 class="ui header">Add Balance</h2>
		</div>
		<div class="right aligned six wide column" *ngIf="currentTab!=tabs[0]">
			<button class="ui right floated negative basic button" (click)="cancelTransactionModal.toggle();">Cancel Transaction</button>
		</div>
	</div>
</div>

<div class="ui form" app-loading [enabled]="loading">

	<app-amis-card-search *ngIf="currentTab === tabs[0]" (submitEvent)="search($event);"></app-amis-card-search>

	<ng-container *ngIf="currentTab !== tabs[0] && purchaseBalanceData">
		<div class="inline field" style="margin: 0;">
			<label>Patron AMIS Card Number:</label><span>{{purchaseBalanceData.card.cardNumber}}</span>
		</div>
		<div class="inline field" style="margin: 0;">
			<label>Card Expiration Date:</label><span>{{purchaseBalanceData.card.endDate | date : 'dd/MM/yyyy'}}</span>
		</div>
		<div class="inline field" style="margin: 0;">
			<label>Entitlement Code:</label><span>{{purchaseBalanceData.card.entitlementCode}}</span>
		</div>
		<div class="inline field" style="margin: 0;">
			<label>Fuel Override:</label><span>{{purchaseBalanceData.card.fuelOverride}}</span>
		</div>
	</ng-container>

	<container-element [ngSwitch]="currentTab">
		<app-rationed-types-pick *ngSwitchCase="tabs[1]"
			[rationedItems]="rationedItems"
			[(selectedRationedItem)]="selectedRationedItem"
			[currentPosLocationInfo]="currentPosLocationInfo"
			[balanceConfirmationItem]="balanceConfirmationItem"
			(action)="onAction($event)">
		</app-rationed-types-pick>
		<app-confirmation *ngSwitchCase="tabs[2]"
			[expirationPeriods]="expirationPeriods"
			[reasons]="addBalanceReasons"
			[balanceConfirmationItem]="balanceConfirmationItem!"
			[selectedRationedItem]="selectedRationedItem"
			(action)="onAction($event)"></app-confirmation>
	</container-element>
</div>

<app-cancel-transaction (cancelTransactionEvent)="cancelTransaction()"></app-cancel-transaction>