import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@nstep-common/core';
import { createProxy } from '@nstep-common/utils';
import { AmisCardModel, AmisCardService, OnlineTransactionModelValidator } from '@nstep-public/shared';
import { ValidationErrors } from 'fluentvalidation-ts';

@Component({
	selector: 'app-amis-card-search',
	templateUrl: './amis-card-search.component.html',
})
export class AmisCardSearchComponent extends BaseComponent implements OnInit {
	@Output() submitEvent = new EventEmitter<string>();

	isValid = false;

	amisCardModel: AmisCardModel = createProxy(new AmisCardModel(), {
		set: () => this.validate(this.amisCardModel)
	});

	validation: ValidationErrors<AmisCardModel> = {};

	validate(value: AmisCardModel): void {
		const validator = new OnlineTransactionModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private amisCardService: AmisCardService) {
		super()
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.amisCardService.currentAmisCardNumberValue.subscribe(
				(value: string) => this.amisCardModel.amisCardNumber = value
			)
		);
	}

	onSubmit() {
		this.submitEvent.emit(this.amisCardModel.amisCardNumber);
	}
}
