import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { ExtendedPOSLocationDto } from '@nstep-public/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class OperatorService {

	constructor(private apiService: ApiService) { }

	getLocations(): Observable<any> {
		return this.apiService.get(Any, 'pos-operator/locations');
	}

	setLocationInfo(posLocationInfo: ExtendedPOSLocationDto): void {
		localStorage.setItem('locationInfo', JSON.stringify(posLocationInfo));
	}

	getLocationInfo(): string {
		return localStorage.getItem('locationInfo') || '';
	}
}
