<div class="ui divider"></div>

<div class="ui form">
	<h3 class="ui header">1. Pick Entitlement</h3>
	<div class="fields">
		<div class="three wide field">
			<label>Entitlement Type:</label>
			<sm-dropdown class="ui selection dropdown"
				[class.loading]="!entitlementTypeDropdownValues.length"
				[(value)]="selectedRationedItem.entitlementTypeId"
				[options]="entitlementTypeDropdownValues"
				(valueChange)="onEntitlementTypeValueChange()">
				<div class="text"></div>
				<i class="dropdown icon"></i>
			</sm-dropdown>
			<div *ngIf="rationedItemsBalance && rationedItemsBalance[selectedRationedItem.entitlementTypeId]"
				class="ui pointing blue label">
				Current Balance: {{rationedItemsBalance[selectedRationedItem.entitlementTypeId].balance}}
			</div>
		</div>
		<div class="thirteen wide field">
			<label>Unit Count:</label>
			<div class="fields">
				<div class="three wide field" app-field [error]="validation | path: 'unitCount'">
					<input type="number" [min]="selectedRationedItem.minimumBuyAmount" [step]="selectedRationedItem.minimumBuyAmount" [(ngModel)]="entitlementPickModel.unitCount" onkeydown="return false">
				</div>

				<div class="field">
					<button class="ui positive basic button" [disabled]="!isValid" (click)="next()">Next</button>
				</div>
			</div>
		</div>

	</div>
	<div class="field">
		<app-table-view
			[itemTemplate]="rationedTypesPickItemTemplate"
			[data]="tableData"
			[noSearch]="true"
			[dataReady]="tableDataReady"
			[columns]="tableColumns">
		</app-table-view>
	</div>
</div>

<ng-template #rationedTypesPickItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td>{{item.date | date : "dd/MM/yyyy"}}</td>
		<td>+{{item.transactionAmount }}</td>
		<td>{{item.operator }}</td>
		<td>{{item.posLocation }}</td>
	</tr>
</ng-template>